<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-3">
                                <!-- <input type="file" @change="handleFileChange" accept=".txt" /> -->
                                <v-text-field
                                    v-model="idModel"
                                    label="image ID"
                                    hide-details
                                ></v-text-field>
                            </div>
                            <div class="col-3">
                                <v-text-field
                                    v-model="imgNameModel"
                                    label="image Name"
                                    hide-details
                                ></v-text-field>
                            </div>
                            <div class="col-3">
                                <v-btn
                                    class="text-none text-subtitle-1"
                                    color="#5865f2"
                                    size="small"
                                    variant="flat"
                                    @click="getFile"
                                >decrypt</v-btn>
                            </div>
                                <img v-if="decryptedImage" :src="decryptedImage" alt="Decrypted Image" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div>
                            <div id="pdf_container"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

import CryptoJS from 'crypto-js';

export default {
    components: {
        
    },
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Blank',
                disabled: false,
                href: '#',
                },
                {
                text: 'Blank',
                disabled: true,
                href: '#',
                },
            ],
            image: null,
            previewImage: null,
            name: null,
            address: null,
            encryptedImage: '',
            file: null,
            encryptedData: '',
            decryptedImage: null,
            idModel: null,
            imgNameModel: '',
            customValues: [
                { name: 'Banana', value: 10 },
                { name: 'Apple', value: 20 },
                { name: 'Orange', value: 30 },
            ],
        }
    },
    created() {
        
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        initialize(){
            
            

        },       

        handleFileChange(event) {
            this.image = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(this.image);
            reader.onload = (e) => {
                this.previewImage = e.target.result;
            };
        },

        handleFileChange(event) {
            this.file = event.target.files[0];
        },

        decryptData() {
            if (!this.file) {
                alert('Please select a file first!');
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                this.encryptedData = e.target.result;
                this.decryptString(this.encryptedData);
            };
            reader.readAsText(this.file);
        },

        decryptString(encryptedData) {
            let decryptedData = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse("6E48CB653852431EF3E3D3339B77A73F"), {
                iv: CryptoJS.enc.Utf8.parse("6E48CB653852431EF3E3D3339B77A73F".substring(8, 24)),
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC
            });
            let decryptedString = decryptedData.toString(CryptoJS.enc.Utf8);
            let jsonData = JSON.parse(decryptedString);
            console.log(jsonData);
            const base64DecodedData = atob(jsonData.content);
            const dataArray = new Array(base64DecodedData.length);
                for (let i = 0; i < base64DecodedData.length; i++) {
                dataArray[i] = base64DecodedData.charCodeAt(i);
            }
            const uint8Array = new Uint8Array(dataArray);
            const blob = new Blob([uint8Array]);
            const url = window.URL.createObjectURL(blob);
            this.decryptedImage = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = this.imgNameModel + '.jpg';
            // a.download = 'tessss.jpg';
            a.click();
            window.URL.revokeObjectURL(url);
        },

        async getFile() {

            await axios.get(`${process.env.VUE_APP_URL}/api/test_me?id=${this.idModel}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                // console.log(res.data['data']);
                // this.decryptString(res.data['data']);
                this.decryptStringPDF(res.data['data']);

            })
            .catch(err => {
                
                console.log(err);                

            })

        },

        decryptStringPDF(encryptedData) {
            let decryptedData = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse("6E48CB653852431EF3E3D3339B77A73F"), {
                iv: CryptoJS.enc.Utf8.parse("6E48CB653852431EF3E3D3339B77A73F".substring(8, 24)),
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC
            });

            let decryptedString = decryptedData.toString(CryptoJS.enc.Utf8);
            const pdfArrayBuffer = this.base64ToUint8Array(atob(decryptedString));
            const pdfBlob = new Blob([pdfArrayBuffer], { type: 'application/pdf' });
            
            // Show the decrypted PDF
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const pdfFrame = document.createElement('iframe');
            pdfFrame.src = pdfUrl;
            pdfFrame.width = '100%';
            pdfFrame.height = '500px';
            document.getElementById('pdf_container').appendChild(pdfFrame);
            
            // Add a download button
            const downloadButton = document.createElement('button');
            downloadButton.textContent = 'Download PDF';
            downloadButton.onclick = function() {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = 'decrypted_pdf.pdf';
            link.click();
            };
            document.getElementById('pdf_container').appendChild(downloadButton);

        },

        base64ToUint8Array(base64String) {
            const uint8Array = new Uint8Array(base64String.length);
            for (let i = 0; i < base64String.length; i++) {
                uint8Array[i] = base64String.charCodeAt(i);
            }
            return uint8Array;
        }

    },

    watch: {

        
    }
    
}
</script>

<style scoped>



</style>